export default {
  computed: {
    breadcrumbsContents: function () {
      let text = "全部";
      for (let i of this.options) {
        if (i.value === this.choosed) {
          text = i.name;
        }
      }
      return [
        { text: "首页", url: "/" },
        { text: this.nameExtra.text, url: this.nameExtra.url+'/' + this.choosed },
        { text: text, url: this.nameExtra.url+'/' + this.choosed },
      ];
    },
  },
}