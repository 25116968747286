<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="radio-wrapper">
        <span
          class="option-td"
          v-for="(item, index) in options"
          :key="index"
          @click="linkTo(nameExtra.url+'/' + item.value)"
          :style="
            choosed === item.value
              ? { background: '#00508e', color: '#fff' }
              : {}
          "
          >{{ item.text }}</span
        >
      </div>
      <div class="wrapper-box">
        <div class="ele-item" v-for="(ele, index) in contentItems" :key="index">
          <div class="ele-inner-wrapper" @click="showImg(ele.url)">
            <div class="ele-a">
              <img :src="urlTrans(ele.url[0])" alt="" />
            </div>
          </div>
          <div class="ele-c">
            <div class="b-link">
              <p>
                {{ ele.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper">
        <Page :total="totalItems" :page-size="Tpage.currentSize" @on-change="handlePage" class-name="page-d" />
      </div>
      <Spin v-if="spin" fix size="large"></Spin>
    </div>
    <Modal
      v-model="modalSwicth.largeImg"
      width="fit-content"
      :mask-closable="false"
      @on-cancel="modalCancel('largeImg')"
      :styles="{ top: '5px' }"
      :z-index="9999999999"
    >
      <p slot="header" class="sc-header"></p>
      <i
        class="ivu-icon ivu-icon-ios-close"
        slot="close"
        @click="modalCancel('largeImg')"
      ></i>
      <div class="sc-content-wrapper">
        <div class="img-wrapper" v-for="(img,index) in currentImg" :key="index">
          <img :src="urlTrans(img)" alt="" style="height: 800px" />
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
import breadcrumbsText from "@/libs/mixins/BreadcrumbsText.js";
export default {
  name: "qualificationAndReputation",
  components: {
    breadcrumbs,
  },
  props: {
    choosed: {
      type: String,
      required: true,
      default: "all",
    },
  },
  mixins: [breadcrumbsText],
  data() {
    return {
      nameExtra:{
          url:"/qualification_reputation",
          text:"资质荣誉",
      },
      code:"certificate",
      lang:"中文",
      options: [
        { text: "全部", value: "all" },
        // { text: "资质证书", value: "qualification" },
        // { text: "荣誉证书", value: "reputation" },
      ],
      modalSwicth: {
        largeImg: false,
      },
      currentImg:[],
      keyword:"",
      contentItems: [
        // {
        //   url:[
        //     process.env.BASE_URL + "img/certificate/cma.jpg",
        //   ],
        //   name: "CMA证书",
        // },
        // {
        //   url:[
        //     process.env.BASE_URL + "img/certificate/curc.png",
        //   ],
        //   name: "CURC资质证明-SRCC-国推认证",
        // },
        // {
        //   url:[ process.env.BASE_URL + "img/certificate/env.jpg",],
        //   name: "环境管理体系认证证书",
        // },
        // {
        //   url:[ process.env.BASE_URL + "img/certificate/iso.jpg",],
        //   name: "质量管理体系认证证书",
        // },
        // {
        //   url:[ process.env.BASE_URL + "img/certificate/jiading.jpg",],
        //   name: "嘉定区企业技术中心 证书",
        // },
        // {
        //   url:[ process.env.BASE_URL + "img/certificate/role.jpg",],
        //   name: "上海市“专精特新”中小企业证书",
        // },
        // {
        //   url:[ process.env.BASE_URL + "img/certificate/sh.jpg",],
        //   name: "职业健康安全管理体系认证证书",
        // },
      ],
      spin: false,
      Tpage: {
            currentPage: 1,
            currentSize: 12
          },
      totalItems:0,
    };
  },
  created(){
    this.getData();
    this.getOption();
  },
  methods: {
    getOption() {
      this.$axios
        .post(this.$url + "/option", {
          askData: this.code,
          lang:this.lang,
        })
        .then((response) => {
          for(let i of response.data.res_record.category){
            this.options.push(i);
          }
        });
    },
    handlePage(value) {
      this.Tpage.currentPage = value;
      this.getData();
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/flist', {
          page: this.Tpage,
          askData:this.code,
          keyword:this.keyword,
          choosed:this.choosed,
          lang:this.lang,
        })
        .then(
          response => {
            this.contentItems = response.data.res_record;
            this.totalItems = response.data.total_n;
            this.spin = false;
          }
        );
    },
    linkTo(value) {
      this.spin = true;
      location.href = value;
    },
    modalCancel(label) {
      this.modalSwicth[label] = false;
    },
    urlTrans(url) {
      // return url;
      if(url){
        return this.$fileUrlFacWithoutRight(url);
      }else{
        return "";
      }
      
    },
    showImg(url) {
      this.currentImg = url;
      this.modalSwicth.largeImg = true;
    },
  },
  computed: {
  },
  mounted() {},
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box {
  padding-top: 50px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.ele-item {
  width: fit-content;
  padding: 0 5px;
}

.ele-inner-wrapper {
  border: 1px dotted #00508e;
  height: 429px;
  padding: 5px;
}

.ele-inner-wrapper:hover{
  outline: 2px solid #00508e;
  border:1px solid #fff;
}

.ele-a {
  overflow: hidden;
  cursor: pointer;
}

@keyframes it {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.ele-a img:hover {
  animation: it 10s ease 0s forwards;
}

.ele-a {
  text-align: center;
}

.ele-a img {
  width: 296px; /*210 */
  max-height: 419px; /*297 */
  display: block;
}

.ele-c {
  padding: 10px 0;
  margin-bottom: 50px;
}

.b-link {
  font-size: 16px;
  color: #666;
  line-height: 150%;
  text-align: center;
}

.page-wrapper {
  text-align: center;
  padding-bottom: 50px;
}

.radio-wrapper {
  position: absolute;
  right: 0;
  top: 50px;
}

.option-td {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-right: none;
}

.option-td:last-child {
  border-right: 1px solid #ddd;
}

.option-td:hover {
  outline: 1px solid #00508e;
  outline-offset: -1px;
}

.sc-content-wrapper {
  text-align: center;
}

.img-wrapper{
  margin:0 5px;
}
</style>